<template>
    <main id="liste" v-cloak>
        <HeaderTab :title="$t('compta.facturation_fournisseur')" :swiper_tabs="swiper_tabs" />

        <div id="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div>
                            <router-view></router-view>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>


<script type="text/javascript">
    import Navigation from "@/mixins/Navigation.js";

	export default {
		name: "Invoice",
		mixins: [Navigation],
		data () {
			return {
                param_tab: '',
                actual_tab: 'invoiceList'
			}
		},

		mounted() {
            this.param_tab = this.$route.params.actual_tab
		},

        computed: {
            swiper_tabs() {
                return [{
                        id: 'control',
                        label: 'invoice.supplier_control',
                        active: false,
                        href : 'invoiceSupplierControl',
                    }, {
                        id: 'checked',
                        label: 'invoice.supplier_checked',
                        active: false,
                        href : 'invoiceSupplierChecked'
                    }, {
                        id: 'payment',
                        label: 'invoice.supplier_payments',
                        active: false,
                        href : 'invoiceSupplierPayment'
                    }
                ]
            },

            hasSaillieAccess() {
                return (this.$store.state.userAccess.hasSaillieAccess)
            },
        },
		components: {
            HeaderTab: () => import('@/components/HeaderTab'),
		}
	}
</script>
